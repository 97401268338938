import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Meta from "@components/meta"
import * as Heading from "@components/heading"
import BreadCrumbs from "../components/breadcrumbs";
import Chevron from "@svgs/chevron.svg";

export default class BlogList extends React.Component {
    render() {
        const posts = this.props.data.allMarkdownRemark.edges
        const { currentPage, numPages } = this.props.pageContext
        const isFirst = currentPage === 1
        const isLast = currentPage === numPages
        const prevPage = currentPage - 1 === 1 ? "/news" : (currentPage - 1).toString()
        const nextPage = (currentPage + 1).toString()

        return (
            <Layout>
                <Meta
                    title="お知らせ"
                    desc="木原興業の最新情報です"
                />{ }
                <Heading.H1 text="お知らせ" />
                <div className="px-5 mb-12 lg:max-w-4xl lg:mx-auto">
                    <BreadCrumbs
                        lists={[
                            {
                                string: "会社情報",
                                path: "/company",
                            },
                            {
                                string: "お知らせ",
                            }
                        ]}
                    />
                    <section>
                        <div className="post-list">
                            {posts.map(({ node }) => {
                                const title = node.frontmatter.title || node.fields.slug;
                                let item;
                                if (node.frontmatter.url) {
                                    item =
                                        <article key={posts.id}>
                                            <Link className="button" to={node.frontmatter.url} target="_blank" rel="noopener noreferrer">
                                                <header>
                                                    <Heading.H3 text={node.frontmatter.date} />
                                                    <div className="text-lg" key={node.fields.slug}>{title}</div>
                                                </header>
                                            </Link>
                                        </article>
                                } else {
                                    item =
                                        <article key={posts.id}>
                                            <Link className="button" to={node.fields.slug}>
                                                <header>
                                                    <Heading.H3 text={node.frontmatter.date} />
                                                    <div className="text-lg" key={node.fields.slug}>{title}</div>
                                                </header>
                                            </Link>
                                        </article>
                                }
                                return (
                                    item
                                )
                            })}
                        </div>
                    </section >
                </div >
                <div className="flex m-2 justify-center text-primary items-center">
                    {!isFirst && (
                        <Link className="mr-2" to={prevPage} rel="prev">
                            <Chevron
                                className="w-3 my-2 fill-current text-primary transform rotate-180"
                            />
                        </Link>
                    )}
                    {Array.from({ length: numPages }, (_, i) => (
                        <div className="flex bg-secondary rounded-full justify-center items-center w-8 h-8 mr-1 ml-1">
                            <Link className="" key={`pagination-number${i + 1}`} to={`/news/${i === 0 ? "" : i + 1}`}>
                                {i + 1}
                            </Link>
                        </div>
                    ))}
                    {!isLast && (
                        <Link className="ml-2" to={nextPage} rel="next">
                            <Chevron
                                className="w-3 my-2 fill-current text-primary"
                            />
                        </Link>
                    )}
                </div>
            </Layout >
        )
    }
}

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            url
            date(formatString: "YYYY年MM月DD日")
          }
        }
      }
    }
  }
`